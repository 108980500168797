@font-face {
  src: url("inter.81753b19.ttf");
}

@media (prefers-color-scheme: dark) {
  .navbar, .dropdown-menu {
    color: #b8d8e1 !important;
    background-color: #1a4a57 !important;
  }

  .dropdown-item:hover {
    background-color: #12323b !important;
  }

  .nav-text {
    color: #b8d8e1 !important;
  }

  .navbar-toggler {
    background-color: #b8d8e1 !important;
  }

  .main-name, .page h1, .page h2, .page h3, .gallery h1, .gallery h3, .gallery h5 {
    color: #85aad5 !important;
  }

  .main-subtitle, a:link, a:visited, .page p, .page li, .form-label, .gallery p {
    color: #fff !important;
  }

  body {
    background-color: #073a48 !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 350px) {
  .main-name {
    font-size: 5.5rem !important;
  }
}

@media only screen and (max-width: 349px) {
  .main-name {
    font-size: 4rem !important;
  }
}

@media only screen and (max-width: 991px) {
  .main-subtitle {
    font-size: 1rem !important;
  }

  .nav-links {
    font-size: 2rem !important;
  }

  .page h1 {
    font-size: 2.5rem !important;
  }

  .page h2 {
    font-size: 1.5rem !important;
  }

  .page h3 {
    font-size: 1.2rem !important;
  }

  .page img {
    width: 90vw !important;
  }
}

.navbar {
  background-color: #b8d8e1;
}

.nav-text {
  color: #1a4a57;
}

.navbar-toggler {
  background-color: #fff;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.main-name {
  color: #062389;
  margin-top: 5%;
  font-size: 8rem;
  font-weight: bold;
}

.page h1, .page h2, .page h3, .gallery h1, .gallery h3, .gallery h5 {
  color: #062389;
  font-weight: bold;
}

.page h1, .gallery h1 {
  font-size: 4rem;
}

.page h2 {
  font-size: 2.5rem;
}

.page h3 {
  font-size: 1.75rem;
}

.page img {
  width: 35vw;
}

.page, .gallery {
  margin-top: 1vh;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.main-subtitle {
  color: #000;
  margin-top: 1%;
  font-size: 2rem;
}

.nav-links {
  margin-top: 30vh;
  font-size: 2.5rem;
}

a:link, a:visited {
  color: #000;
}

.nav-links-item {
  margin-left: 1vw;
  margin-right: 1vw;
}

.navbar-brand {
  margin-left: 1rem;
  font-size: 1.65rem;
}

.navbar-nav {
  margin-right: 1rem;
}

body {
  background-color: #def0f5;
  font-family: Inter, sans-serif;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}

/*# sourceMappingURL=main.css.map */
